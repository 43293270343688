<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <!-- <el-button class="mr10" type="primary">添加分类</el-button> -->
        <el-input class="w220 mr10" v-model="keyWord" placeholder="文章标题" />
        <el-date-picker
          v-model="pickerValue"
          class="mr10"
          align="right"
          value-format="yyyy-MM-dd"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        />
        <el-button type="primary" @click="fnQueryClick">查询</el-button>
      </div>
      <div style="margin-left: auto">
        <!-- <el-button type="primary" @click="$router.push('/isDrafts')"
          >草稿箱</el-button
        > -->
        <el-button
          class="ml10"
          type="primary"
          @click="centerDialogVisible = true"
          >发布动态</el-button
        >
      </div>
    </div>
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column type="index" align="center" label="序号" />
        <el-table-column
          prop="createTime"
          align="center"
          label="创建时间"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div>
              <el-image
                style="width: 80px; height: 80px"
                :src="scope.row.photosList[0]"
                :preview-src-list="scope.row.photosList"
              >
              </el-image
              >×{{ scope.row.photosList.length }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          align="center"
          show-overflow-tooltip
          label="动态内容"
        />
        <el-table-column
          prop="createTime"
          align="center"
          label="创建时间"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ setDataTimeSec(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="shareNum" align="center" label="分享次数" />
        <el-table-column prop="readNum" align="center" label="阅读次数" />
        <el-table-column prop="readTime" align="center" label="阅读时长" />
        <!-- <el-table-column prop="isDrafts" align="center" label="发布状态">
                    <template slot-scope="scope">
                        <el-switch :value="scope.row.isDrafts == 1 ? false : true"
                            @change="(e) => switchChange(e, scope.row.dynamicId)" active-text="发布" inactive-text="关闭">
                        </el-switch>
                    </template>
                </el-table-column> -->
        <el-table-column prop="isTop" align="center" label="是否置顶">
          <template slot-scope="scope">
            <el-switch
              :value="scope.row.isTop == 1 ? true : false"
              @change="(e) => switchChange(e, scope.row.dynamicId, 'stick')"
              active-text="是"
              inactive-text="否"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="150">
          <template slot-scope="scope">
            <el-button class="mr10" type="text" @click="edit(scope.row)"
              >编辑</el-button
            >
            <customPopconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="del(scope.row.dynamicId)"
              icon="el-icon-info"
              icon-color="red"
              title="确定要删除这篇动态吗？"
            >
              <el-button slot="reference" style="color: #fd634e" type="text"
                >删除</el-button
              >
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      title="发布动态"
      :visible.sync="centerDialogVisible"
      :destroy-on-close="true"
      width="600px"
      center
    >
      <dynamic-post-articles
        @submitForm="submitForm"
        ref="dynamicPostArticles"
      ></dynamic-post-articles>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import dynamicPostArticles from "./components/dynamicPostArticles";
import customPopconfirm from "@/components/common/customPopconfirm";
import {
  getDynamicInfo,
  updateDynamicState,
  addDynamicInfo,
  delDynamicInfo,
  updateDynamicTop,
} from "@/api/articleManagement";
import { getDataTimeSec } from "@/utils";
export default {
  name: "customerArticles",
  components: {
    commonTable,
    dynamicPostArticles,
    customPopconfirm,
  },
  data() {
    return {
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      keyWord: "", //文章标题
      pickerValue: "", //选择时间
      centerDialogVisible: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      userInfo: this.$store.state.loginRoot.userInfo,
      tableData: [],
    };
  },
  created() {
    this.getDynamicInfoAsync();
  },
  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  methods: {
    /**@method 获取文章管理 */
    async getDynamicInfoAsync(payload) {
      let params = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        isDrafts: 0,
        type: 2,
        ...payload,
      };
      try {
        this.loading = true;
        const result = await getDynamicInfo(params);
        this.loading = false;

        const { data } = result;
        let tableData = [];
        for (let row of data.pageInfo.list) {
          row.photosList = row.photos.split(",");
          tableData.push(row);
        }
        this.tableData = tableData;
        this.total = data.pageInfo.total;
      } catch (error) {
        //-console.log(error);
        this.loading = false;
        this.tableData = [];
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDynamicInfoAsync();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDynamicInfoAsync();
    },
    /**@method 切换文章发布状态
     * @param {Boolean} e - switch值
     * @param {String} id - id
     */
    async switchChange(e, id, type) {
      let data = {
        dynamicId: id,
        isDrafts: e ? 0 : 1,
      };
      if (type) {
        data = {
          dynamicId: id,
          isTop: e ? 1 : 0,
        };
        updateDynamicTop(data)
          .then((res) => {
            if (e) {
              this.$message.success("置顶成功！");
            } else {
              this.$message.success("取消置顶！");
            }
            this.getDynamicInfoAsync();
          })
          .catch((err) => {
            if (err.code == 201) {
              //-console.log(err);
              this.$message.error(err.message);
            }
          });
      } else {
        await updateDynamicState(data);
        await this.getDynamicInfoAsync();
      }
    },
    /**@method 查询 */
    fnQueryClick() {
      this.currentPage = 1;
      let data = {
        startTime: this.pickerValue ? `${this.pickerValue[0]} 00:00:00` : "",
        endTime: this.pickerValue ? `${this.pickerValue[1]} 23:59:59` : "",
        title: this.keyWord,
      };
      this.getDynamicInfoAsync(data);
    },
    determine(data) {
      this.$refs.dynamicPostArticles.submitForm(data);
    },
    /**
     * @method 发布文章提交
     * @param {Object} val - 表单值
     */
    async submitForm(val) {
      let data = {
        // isDrafts: val.isDrafts ? 0 : 1,
        type: 2,
        ...val,
      };
      data.isDrafts = 0;
      try {
        await addDynamicInfo(data, { showLoading: true });
        this.centerDialogVisible = false;
        await this.getDynamicInfoAsync();
      } catch (error) {
        //-console.log(error);
        this.$message.error(error.message);
      }
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val) {
      this.centerDialogVisible = true;
      const {
        dynamicId,
        companyId,
        title,
        photos,
        details,
        originAuthor,
        author,
        createUserId,
        createTime,
        isDel,
        isTop,
        isDrafts,
      } = val;
      let data = {
        dynamicId,
        companyId,
        title,
        photos,
        details,
        originAuthor,
        author,
        createUserId,
        createTime,
        isDel,
        isTop: isTop ? true : false,
        isDrafts: isDrafts ? true : false,
      };
      //-console.log(val);
      //-console.log(data);
      this.$nextTick(() => {
        this.$refs.dynamicPostArticles.ruleForm = data;
        if (photos) {
          for (let url of photos.split(",")) {
            this.$refs.dynamicPostArticles.fileList.push({
              name: "",
              url: url,
            });
            this.$refs.dynamicPostArticles.photosData.push(url);
          }
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delDynamicInfo({ dynamicId: val });
        await this.getDynamicInfoAsync();
      } catch (error) {
        //-console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>